import { all } from 'redux-saga/effects';
import watchApplyForJobSaga from './apply-for-job.saga';
import watchDirectoriesSaga from './directories/directories.sagas';
import watchRegisterSaga from './register.saga';
import watchContactsSaga from './contacts.saga';

export default function* () {
	yield all([
		watchApplyForJobSaga(),
		watchDirectoriesSaga(),
		watchRegisterSaga(),
		watchContactsSaga(),
	]);
}
