export const countries = [
	{
		label: 'Nigeria',
		value: 'NGA',
	},
	{
		label: 'United States',
		value: 'USA',
	},
];
