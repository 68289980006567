import { colors } from '../../../common/constants';

const secondary = {
	border: `1px solid ${colors.primary.main}`,
	borderRadius: '4px',
	height: '44px',
	// paddingInlineStart: '38px',
	// paddingInlineEnd: '38px',
	fontSize: '14px',
	lineHeight: '20px',
	fontWeight: '600',
	color: colors.primary.main,
	_hover: {
		color: colors.primary.hovered,
		border: `1px solid ${colors.primary.hovered}`,
	},
	_active: {
		color: colors.primary.pressed,
		border: `1px solid ${colors.primary.pressed}`,
	},
	_focus: {
		boxShadow: `0 0 0 3px ${colors.primary.focused}`,
	},
	_disabled: {
		color: colors.neutral[50],
		border: `1px solid ${colors.neutral[50]}`,
	},
};

export const Button = {
	baseStyle: {},
	sizes: {},
	variants: {
		primary: {
			backgroundColor: colors.primary.main,
			color: 'white',
			fontWeight: '600',
			fontSize: '16px',
			lineHeight: '24px',
			// paddingInlineStart: '50px',
			// paddingInlineEnd: '50px',
			height: '50px',
			borderRadius: '4px',
			_hover: {
				backgroundColor: colors.primary.hovered,
			},
			_active: {
				backgroundColor: colors.primary.pressed,
			},
			_focus: {
				boxShadow: `0 0 0 3px ${colors.primary.border}`,
			},
			_disabled: {
				backgroundColor: colors.neutral[40],
			},
		},
		secondary,
		with_chevron: {
			...secondary,
			height: '50px',
		},
	},
};
