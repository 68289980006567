import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadError, ContactUsForm } from '../../types';

type InitialState = {
	loading: boolean;
	errors: PayloadError[];
};

const initialState: InitialState = {
	loading: false,
	errors: [],
};

const contacts = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		sendContactRequest: (state, action: PayloadAction<ContactUsForm>) => {
			state.loading = true;
			state.errors = [];
		},
		sendContactSuccess: state => {
			state.loading = false;
		},
		sendContactError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});
export const { sendContactError, sendContactRequest, sendContactSuccess } =
	contacts.actions;

export default contacts.reducer;
