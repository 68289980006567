import React from 'react';
import FacebookIcon from '../../assets/FacebookIcon.svg';
import TwitterIcon from '../../assets/TwitterIcon.svg';
import InstagramIcon from '../../assets/InstagramIcon.svg';
import GithubIcon from '../../assets/GithubIcon.svg';
import LinkedinIcon from '../../assets/LinkedinIcon.svg';
import FacebookSquareIcon from '../../assets/FacebookSquareIcon.svg';

export const adult_footer_links = [
	{ displayName: 'About', route: '/', gridName: 'one' },
	{ displayName: 'Courses', route: '/courses', gridName: 'two' },
	{ displayName: 'Jobs', route: '/', gridName: 'three' },
	{ displayName: 'About us', route: '/about', gridName: 'four' },
	{ displayName: 'Contact', route: '/contacts', gridName: 'five' },
	{ displayName: 'Legal and privacy', route: '/', gridName: 'six' },
];

export const teens_footer_links = [
	{ displayName: 'About', route: '/teens', gridName: 'one' },
	{ displayName: 'Courses', route: '/teens/courses', gridName: 'two' },
	{ displayName: 'Jobs', route: '/teens', gridName: 'three' },
	{ displayName: 'About us', route: '/about', gridName: 'four' },
	{ displayName: 'Contact', route: '/contacts', gridName: 'five' },
	{ displayName: 'Legal and privacy', route: '/teens', gridName: 'six' },
];

export const footer_social_links = [
	{
		icon: <LinkedinIcon />,
		url: 'https://www.linkedin.com/company/salina-code-academy',
	},
	{
		icon: <FacebookSquareIcon />,
		url: 'https://www.facebook.com/salinacodeacademy/',
	},
	{
		icon: <InstagramIcon />,
		url: 'https://www.instagram.com/salinacodeacademy_/?hl=en',
	},
];
