import { extendTheme } from '@chakra-ui/react';
import { colors } from '../../common/constants';
import { Text, Heading, Button } from './components';

const theme = {
	colors,
	fonts: {
		heading: 'Inter',
		body: 'Inter',
	},
	components: {
		Button,
		Text,
		Heading,
	},
};

export default extendTheme(theme);
