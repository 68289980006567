import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponseEmpty } from '../../types';
import {
	applyForJobError,
	applyForJobRequest,
	applyForJobSuccess,
} from '../reducers/apply-for-job.reducer';
import API from '../../common/api/api.base';
import { Alerter } from '../../common/helpers';

function* workerApplyForJob(action: ReturnType<typeof applyForJobRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(
			API.post,
			'/api/v1/job-applications/job-teacher-application',
			action.payload,
		);
		if (result.success) {
			yield put(applyForJobSuccess());
			yield Alerter.success(result.messages[0].messageText);
		} else {
			yield put(applyForJobError(result.errors));
			yield Alerter.error(result.errors[0].errorMessage);
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* watchApplyForJobSaga() {
	yield takeEvery(applyForJobRequest.type, workerApplyForJob);
}
