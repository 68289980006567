import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponseEmpty } from '../../types';
import {
	registerAdultRequest,
	registerAdultSuccess,
	registerAdultError,
	registerTeensRequest,
	registerTeensSuccess,
	registerTeensError,
} from '../reducers/register.reducer';
import API from '../../common/api/api.base';

function* workerRegisterAdult(action: ReturnType<typeof registerAdultRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(
			API.post,
			'/api/v1/auth/register-adult',
			action.payload,
		);
		if (result.success) {
			yield put(registerAdultSuccess());
		} else {
			yield put(registerAdultError(result.errors));
		}
	} catch (error) {
		console.log(error);
	}
}

function* workerRegisterTeens(action: ReturnType<typeof registerTeensRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(
			API.post,
			'/api/v1/auth/register-child',
			action.payload,
		);
		if (result.success) {
			yield put(registerTeensSuccess());
		} else {
			yield put(registerTeensError(result.errors));
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* watchRegisterSaga() {
	yield takeEvery(registerAdultRequest.type, workerRegisterAdult);
	yield takeEvery(registerTeensRequest.type, workerRegisterTeens);
}
