import { createSlice, createAction } from '@reduxjs/toolkit';

type InitialState = {
	adultsSignupOpen: boolean;
	teensSignupOpen: boolean;
};

const initialState: InitialState = {
	adultsSignupOpen: false,
	teensSignupOpen: false,
};

export const toggleAdultsSignupModal = createAction<boolean>(
	'TOGGLE_ADULTS_SIGNUP_MODAL',
);
export const toggleTeensSignupModal = createAction<boolean>(
	'TOGGLE_TEENS_SIGNUP_MODAL',
);

const signupModals = createSlice({
	name: 'signup-modals',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(toggleAdultsSignupModal, (state, action) => {
				state.adultsSignupOpen = action.payload;
			})
			.addCase(toggleTeensSignupModal, (state, action) => {
				state.teensSignupOpen = action.payload;
			});
	},
});

export default signupModals.reducer;
