export const Text = {
	baseStyle: {},
	sizes: {
		S: {
			fontSize: '12px',
			lineHeight: '16px',
		},
		M: {
			fontSize: '14px',
			lineHeight: '20px',
		},
		L: {
			fontSize: '16px',
			lineHeight: '24px',
		},
	},
	variants: {
		regular: {
			fontWeight: '400',
		},
		medium: {
			fontWeight: '600',
		},
	},
	defaultProps: {},
};
