import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, SkillDirectory } from '../../../types';
import {
	getSkillsDirectoryRequest,
	getSkillsDirectorySuccess,
} from '../../reducers/directories/skills-directory.reducer';
import API from '../../../common/api/api.base';

function* workerGetSkillsDirectory() {
	try {
		const result: ApiResponse<{ skills: SkillDirectory[] }> = yield call(
			API.get,
			'/api/v1/directories/skills',
			{},
		);
		if (result.success) {
			yield put(getSkillsDirectorySuccess(result.value.skills));
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* watchSkillsDirectorySaga() {
	yield takeEvery(getSkillsDirectoryRequest.type, workerGetSkillsDirectory);
}
