import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkillDirectory } from '../../../types';

type InitialState = {
	loading: boolean;
	items: SkillDirectory[];
};

const initialState: InitialState = {
	loading: false,
	items: [],
};

const skillsDirectory = createSlice({
	name: 'apply-for-job',
	initialState,
	reducers: {
		getSkillsDirectoryRequest: state => {
			state.loading = true;
		},
		getSkillsDirectorySuccess: (
			state,
			action: PayloadAction<SkillDirectory[]>,
		) => {
			state.loading = false;
			state.items = action.payload;
		},
	},
});
export const { getSkillsDirectoryRequest, getSkillsDirectorySuccess } =
	skillsDirectory.actions;

export default skillsDirectory.reducer;
