import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponseEmpty } from '../../types';
import {
	sendContactError,
	sendContactRequest,
	sendContactSuccess,
} from '../reducers/contacts.reducer';
import API from '../../common/api/api.base';
import { Alerter } from '../../common/helpers';

function* workerSendContactsRequest(
	action: ReturnType<typeof sendContactRequest>,
) {
	try {
		const result: ApiResponseEmpty = yield call(
			API.post,
			'/api/v1/landing/contact-us',
			action.payload,
		);
		if (result.success) {
			yield put(sendContactSuccess());
			yield Alerter.success(result.messages?.[0]?.messageText);
		} else {
			yield put(sendContactError(result.errors));
			yield Alerter.error(result.errors?.[0]?.errorMessage);
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* watchContactsSaga() {
	yield takeEvery(sendContactRequest.type, workerSendContactsRequest);
}
