import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { PayloadError } from './../../types';
import { IAdultsSignupForm } from '../../modals/AdultsSignupModal/types';
import { ITeensSignupForm } from '../../modals/TeensSignupModal/types';
import {
	toggleAdultsSignupModal,
	toggleTeensSignupModal,
} from './signup-modals.reducer';

type InitialState = {
	loading: boolean;
	errors: PayloadError[];
};

const initialState: InitialState = {
	loading: false,
	errors: [],
};

export const registerAdultSuccess = createAction('REGISTER_ADULT_SUCCESS');
export const registerTeensSuccess = createAction('REGISTER_TEENS_SUCCESS');

const registerReducer = createSlice({
	name: 'register',
	initialState,
	reducers: {
		registerAdultRequest: (
			state,
			action: PayloadAction<IAdultsSignupForm>,
		) => {
			state.loading = true;
			state.errors = [];
		},
		registerAdultError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},
		registerTeensRequest: (
			state,
			action: PayloadAction<ITeensSignupForm>,
		) => {
			state.loading = true;
		},
		registerTeensError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(registerAdultSuccess, state => {
				state.loading = false;
			})
			.addCase(registerTeensSuccess, state => {
				state.loading = false;
			})
			.addCase(toggleAdultsSignupModal, (state, action) => {
				if (!action.payload) {
					state.errors = [];
					state.loading = false;
				}
			})
			.addCase(toggleTeensSignupModal, (state, action) => {
				if (!action.payload) {
					state.errors = [];
					state.loading = false;
				}
			});
	},
});

export const {
	registerAdultError,
	registerAdultRequest,
	registerTeensError,
	registerTeensRequest,
} = registerReducer.actions;

export default registerReducer.reducer;
