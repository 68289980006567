export const Heading = {
	baseStyle: {},
	sizes: {
		XS: {
			fontSize: '14px',
			lineHeight: '20px',
			fontWeight: '600',
		},
		S: {
			fontSize: '20px',
			lineHeight: '32px',
			fontWeight: '500',
		},
		M: {
			fontSize: '28px',
			lineHeight: '40px',
			fontWeight: '600',
		},
		L: {
			fontSize: '36px',
			lineHeight: '48px',
			fontWeight: '600',
		},
		XL: {
			fontSize: '44px',
			lineHeight: '56px',
			fontWeight: '600',
		},
	},
	defaultProps: {},
};
