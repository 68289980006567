import { configureStore, Store } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers/root.reducer';
import rootSaga from './sagas/root.saga';

const createStore = (): Store => {
	const sagaMiddleware = createSagaMiddleware();

	const store = configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware => [
			sagaMiddleware,
			...getDefaultMiddleware({
				thunk: false,
			}),
		],
	});

	sagaMiddleware.run(rootSaga);

	return store;
};

export default createStore;
