export const colors = {
	neutral: {
		10: '#FFFFFF',
		20: '#F5F5F5',
		30: '#EBEBEC',
		40: '#D6D6D6',
		50: '#C2C2C2',
		60: '#999999',
		70: '#707070',
		80: '#3D3D3D',
		90: '#171717',
		100: '#0A0A0A',
	},
	primary: {
		main: '#126065',
		surface: '#F1F9F7',
		border: '#AAC6C8',
		hovered: '#0E494D',
		pressed: '#0B3A3D',
		focused: '#84ACAF',
		500: '#126065',
	},
	secondary: {
		main: '#F2C94C',
		surface: '#FDF7F2',
		border: '#FAECBF',
		hovered: '#B8993A',
		pressed: '#91792E',
		focused: '#F8E3A2',
	},
	danger: {
		main: '#CC4844',
		surface: '#F9E9E9',
		border: '#EDBDBC',
		hovered: '#9B3734',
		pressed: '#7A2B29',
		focused: '#E4A09E',
	},
	warning: {
		main: '#F5B77B',
		surface: '#FEF6EF',
		border: '#FBE5CF',
		hovered: '#BA8B5D',
		pressed: '#936E4A',
		focused: '#FADABA',
	},
	success: {
		main: '#A3C0A2',
		surface: '#F4F7F4',
		border: '#DEE8DD',
		hovered: '#7C927B',
		pressed: '#627361',
		focused: '#CFDECF',
	},
};
