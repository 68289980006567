import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadError } from '../../types';

type InitialState = {
	loading: boolean;
	errors: PayloadError[];
};

const initialState: InitialState = {
	loading: false,
	errors: [],
};

const applyForJob = createSlice({
	name: 'apply-for-job',
	initialState,
	reducers: {
		applyForJobRequest: (state, action: PayloadAction<FormData>) => {
			state.loading = true;
			state.errors = [];
		},
		applyForJobSuccess: state => {
			state.loading = false;
		},
		applyForJobError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});
export const { applyForJobError, applyForJobRequest, applyForJobSuccess } =
	applyForJob.actions;

export default applyForJob.reducer;
