import { combineReducers } from 'redux';
import applyForJobReducer from './apply-for-job.reducer';
import { directoriesReducer } from './directories/directories.reducer';
import signupModalsReducer from './signup-modals.reducer';
import contactsReducer from './contacts.reducer';
import registerReducer from './register.reducer';

export const rootReducer = combineReducers({
	applyForJob: applyForJobReducer,
	directories: directoriesReducer,
	signupModals: signupModalsReducer,
	contacts: contactsReducer,
	register: registerReducer,
});
